.rejection-reason-box {
    border: 1px solid #F1416C;
    background-color: #FFF5F8;
    color: #3F4254;
    padding: 10px 15px;
    border-radius: 4px;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.rejection-icon {
    color: #dc3545;
    font-weight: bold;
    margin-right: 8px;
}

.rejection-text {
    flex: 1;
}
