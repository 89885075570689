.button-similar {
    border-radius: 12px;
    width: 150px;
}
.justify-space-between {
    justify-content: space-between;
}
.width-50P {
    width: 50%;
}
.width-100P {
    width: 100%;
}
.padding-top-30 {
    padding-top: 30px;
}
.gap-10px {
    gap: 10px;
}
.align-items-center {
    align-items: center;
}
.font-size-16px {
    font-size: 16px;
}
.background-F3F7F9 {
    background: #F3F7F9;
}
.width-33P {
    width: 33%;
}
.smaller-width {
    width: 70%;
    margin: auto;
}
.popup-overlay {
    opacity: 1 !important;
}
.form-control:focus {
    background-color: #F3F7F9 !important;
}