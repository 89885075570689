.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
}

.popup-content {
    background-color: white;
    padding: 20px;
    width: 90%;
    max-height: 80%;
    overflow-y: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1060;
}

.popup-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5) !important;
    opacity: 0.5!important;
}

.close-btn {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}

.close-btn:hover {
    background-color: #d32f2f;
}
