body {
    background: red;
}
.tag {
    display: inline-block;
    background-color: #e0e7ff;
    color: #333;
    padding: 5px 10px;
    margin: 5px;
    border-radius: 15px;
    font-size: 14px;
}
.text-align-center {
    text-align: center;
}
.tag button {
    background: none;
    border: none;
    color: #888;
    cursor: pointer;
}

.variations-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.variations-table th, .variations-table td {
    padding: 10px;
    border: 1px solid #ddd;
}

.variations-table th {
    background-color: #94ADC7;
    color: #fff;
}

.generate-btn, .upload-btn {
    background-color: #000;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.width-40px {
    width: 40px;
}

.border-style {
    border: 1px solid #94ADC7;
    border-radius: 5px;
}

.form-label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
}

/* Dropdown Wrapper */
.dropdown-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.dropdown-select {
    padding: 8px 12px;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    background-color: #f9fafb;
    font-size: 14px;
    color: #6b7280;
    width: 150px;
    appearance: none;
    cursor: pointer;
}

.dropdown-select:focus {
    outline: 2px solid #2563eb;
    outline-offset: 2px;
}

.add-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #2563eb;
    color: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
}

.add-btn:hover {
    background-color: #1e40af;
}

/* Tags */
.tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.tag {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    background-color: #f3f4f6;
    border: 1px solid #e5e7eb;
    border-radius: 16px;
    font-size: 14px;
    color: #374151;
    white-space: nowrap;
}

.tag button {
    background: none;
    border: none;
    font-size: 14px;
    color: #d32f2f;
    cursor: pointer;
    padding: 0;
}

.tag button:hover {
    color: #b71c1c;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}