.barcode-field {
    flex-direction: column;
    width: 50%;
}
.generate-barcode-btn {
    border-radius: 12px;
    width: 150px;
}
.generate-barcode-but-label {
    display: flex;
    align-items: center;
    gap: 10px;
}
.no-barcode-label {
    color: #402FFF;
}