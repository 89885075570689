@font-face {
    font-family: 'Noto Sans Arabic Bold';
    src: url('../public/fonts/NotoSansArabic-Bold.woff2') format('woff2'),
        url('../public/fonts/NotoSansArabic-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans Arabic';
    src: url('../public/fonts/NotoSansArabic-Regular.woff2') format('woff2'),
        url('../public/fonts/NotoSansArabic-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
.lang-en .only-ar,.lang-ar .only-en {
    display: none!important;
}

body.lang-ar {
    direction: rtl;
    text-align: right;
    font-family: 'Noto Sans Arabic';
    font-size: 15px!important;
}
.lang-ar .me-lg-15 {
    margin-left: 3.75rem !important;
    margin-right: 0 !important;
}
.lang-ar .card .card-header .card-title .card-label {
    margin: 0 0 0 0.75rem;
}
.lang-ar .modal-header .btn-close {
    margin: calc(-0.5 * var(--bs-modal-header-padding-y)) auto calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x));
}
.submit-box-product-page {
    position: absolute;
    top: 40px;
    right: 0;
}
.lang-ar .submit-box-product-page {
    right: inherit;
    left: 0;
}
.default-image-box .upload-img-pin {
    right: -15px;
    top: -15px;
    width: 30px;
    height: 30px;
    z-index: 1;
}
.remove-gallery-img {
    font-size: 20px!important;
    width: 25px;
    height: 24px;
    display: flex;
    flex-direction: row-reverse;
    right: -10px;
    top: -10px;
    transition: .3s;
}
.remove-gallery-img:hover {
    color: #000;
}
.left-0 {
    left: 0;
}
.z-1000 {
    z-index:1000!important;
}
input.no-arrows::-webkit-outer-spin-button,
input.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.w-30 {
    width: 30%;
}
.w-20 {
    width: 20%;
}
.w-15 {
    width: 15%;
}
.w-10 {
    width: 10%;
}
.min-width-20 {
    min-width: 20%;
    max-width: 20%;
}
.min-width-15 {
    min-width: 15%;
    max-width: 15%;
}
.w-250px {
    min-width: 250px;
}
.min-width-200 {
    min-width: 200px;
    max-width: 200px;
    width: 200px;
}
.table-img {
    width: 100px;
}
.flex-1 {
    flex: 1;
}
.billing-total-history-summary {
    width: calc(80% );
}
.table-select-action > button{
    background-color: transparent!important;
    color: var(--kt-form-select-color)!important;
    border: 1px solid #f4f6fa!important;
}
table .table-select-action > button.dropdown-toggle:hover {
    background-color: transparent!important;
}
.lang-ar .table > :not(caption) > * > * {
    padding-left: 0.75rem!important;
}
.lang-ar .table.gs-0 th:first-child, 
.lang-ar .table.gs-0 td:first-child {
    padding-right: 0;
}
.lang-ar .table.gs-0 th:last-child,
.lang-ar  .table.gs-0 td:last-child {
    padding-left: 0rem!important;
}
.lang-ar table .px-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
}
.lang-ar table .last-th {
    padding-right: 0!important;
}
.lang-ar [type="reset"].btn-close-white {
    font-size: 16px;
}
.underline {
    text-decoration: underline;
}
.table-border {
    border-bottom: 1px solid #000!important;
}
.table-border td, .table-border th {
    border: 1px solid #000!important;
}
.generate-barcode-btn {
    padding: 0.775rem 1rem!important;
    font-size: 1.1rem!important;
    font-weight: 500!important;
    line-height: 1.5!important;
    min-width: 70px;
}

@media (max-width: 776px) {
    .table-img {
        width: 60px;
        height: auto;
    }
    .flex-column-p {
        flex-direction: column;
    }
    .flex-wrap-p {
        flex-wrap: wrap;
    }
    .w-100-p {
        width: 100%;
    }
    .logout-btn-header,.font-0-p {
        font-size: 0!important;
    }
    .add-product-page {
        display: flex;
        flex-direction: column;
    }
    .add-product-page .submit-box-product-page {
        position: relative;
        top: auto;
        order: 1;
        margin: 0 0 15px 0!important;
    }
    .add-product-page > .tab-content {
        order: 5;
    }
}
