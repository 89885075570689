.orange-button {
    background-color: orange;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

/* HeaderContainer.css */
.header-container {
    display: grid;
    gap: 10px; /* Add a gap between the two columns */
    align-items: center; /* Vertically align items */
}