.green-text{
    color: darkgreen !important;
}

.red-text{
    color: red !important;;
}

.blue-text{
    color: blue !important;;
}

.td-border {
    border-top: 1px solid black !important;;
}