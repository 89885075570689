.edit-width{
    width: 80%;
}

@media (max-width: 576px) {
    .edit-width {
        width: 100% !important;
        font-size: smaller;
    }
}
