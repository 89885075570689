
.custom-radio {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    /*background-color: #ddd;*/
    border: 2px solid #0F3F75;
    position: relative;
    cursor: pointer;
}

.custom-radio:checked {
    background-color: #0F3F75;
    border-color: #0F3F75;
}

.custom-radio::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    transition: all 0.3s ease;
}

.custom-radio:checked::before {
    background-color: #fff;
}

.default-text-color {
    color: #0F3F75;
}