.category-list {
    padding: 1em;
    background-color: #f4f6f9;
    border-radius: 8px;
}
.category-list h2 {
    color: #007bff;
    font-size: 1.2em;
    margin-bottom: 0.5em;
}
.category-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.category-item {
    display: flex;
    justify-content: space-between;
    padding: 0.75em 1em;
    background-color: #f5f6f9;
    font-size: 1em;
    color: #333333;
    border-bottom: 1px solid #D8DCE5;
}
.category-item .arrow {
    color: #c7c7c7;
    font-weight: bold;
}
.border-bottom-grey {
    border-bottom: 1px solid #D8DCE5;
}
.select-button {
    border-radius: 12px;
    width: 80px;
    background: #D8DCE5;
    border: none;
}